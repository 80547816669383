<template>
    <MainLayout>
        <template #drawerBtn>
            <q-btn
                class="lg:tw-hidden"
                dense
                flat
                round
                icon="menu"
                @click="toggleLeftDrawer"
            />
        </template>
        <template #drawer>
            <LeftDrawer v-model="leftDrawerOpen"></LeftDrawer>
        </template>
        <div class="tw-py-10 lg:tw-py-20">
            <slot></slot>
        </div>
    </MainLayout>
</template>
<script lang="ts" setup>
import MainLayout from "./MainLayout.vue"
import LeftDrawer from "~/components/LeftDrawer.vue"
import { ref } from "vue"

const leftDrawerOpen = ref(false)

function toggleLeftDrawer() {
    leftDrawerOpen.value = !leftDrawerOpen.value
}
</script>
